/* global Foundation:true */

let $ = require("jquery")
//hack jquery into window fixes double jquery import issue.
window.jQuery = window.$ = $

require("foundation-sites/js/foundation.core")
require("foundation-sites/js/foundation.util.keyboard")
require("foundation-sites/js/foundation.util.mediaQuery")
require("foundation-sites/js/foundation.util.triggers")
require("foundation-sites/js/foundation.util.touch")
require("foundation-sites/js/foundation.util.motion")
require("foundation-sites/js/foundation.util.nest")
require("foundation-sites/js/foundation.toggler")
require("foundation-sites/js/foundation.slider")
require("foundation-sites/js/foundation.accordionMenu")

require("device.js")

//nest has a problem
//require('foundation-sites/js/foundation.util.nest');

let SoundPlayer = require("./soundplayer.js")
let Offcanvas = require("./offcanvas-nav.js")
let Galleries = require("./galleries.js")
let Carpet = require("./carpet.js")
let Television = require("./tv.js")
let PDFDownload = require("./pdf-download.js")

let Shariff = require("shariff")

//var smoothScroll = require('smoothscroll');

window.jQuery(() => {
  //this is well....
  //https://github.com/metafizzy/flickity/issues/457
  //window.addEventListener( 'touchmove', function() {});   //ios10 bugfix

  //IMPORTANT CALL THIS ONLY FROM THE PAGE BOTTOM, EVEN IN DEBUG
  // Foundation and Back to top
  $(document).foundation()
  //requirements
  Offcanvas.init(Foundation, window.jQuery)
  SoundPlayer.init(Foundation, window.jQuery)

  //view addons
  Galleries.init(Foundation, window.jQuery)
  Carpet.init(Foundation, window.jQuery)
  Television.init(Foundation, window.jQuery)
  PDFDownload.init(Foundation, window.jQuery)

  //prevent text select on safari and some chromes
  //http://stackoverflow.com/questions/2745028/chrome-sets-cursor-to-text-while-dragging-why
  /*
        $('.slider-handle').on('mousedown', function (e) {
            e.originalEvent.preventDefault();
        });
        */

  //instantiate sharing stuff
  new Shariff($(".shariff"), {})
  $(".shariff").click(() => {
    //emulate a shaiff toggle to close the drawer
    $(".sharing .selection.sharing").click()
  })

  //register the first mouse interaction to close the drawer
  //only do this if not on small screen
  if (Foundation.MediaQuery.current !== "small") {
    $(window).bind("mousedown touchstart", (e) => {
      //if click was in footer util, ignore
      if ($(e.target).parents(".ignore-first-click").length > 0) {
        return
      }
      //else proceed
      $(window).unbind("mousedown touchstart")
      //trigger the toggle switch; (but let the default action happen
      //if visitor clicks the toggle switch itself)
      if (!$(e.target).hasClass("footer")) $("footer button.toggle").click()
    })
  }

  $(window).bind("pageshow", () => {
    $(".content-box--citation")
      .css("opacity", 0)
      .load(
        `/random-quote/?category-id=${$(".content-box--citation").data(
          "category-id"
        )}&category-lang=${$(".content-box--citation").data(
          "category-lang"
        )}&rnd=${Math.round(Math.random() * 9999)}`
      )
      .css("opacity", 1)

    //no need to distinguish between persitent or not
    /*
            if (event.originalEvent.persisted) {
                //came here with the back button, chrome will ignore
            }
            */
  })
})

////check for hash in url
if (window.location.hash && window.location.hash.indexOf("/") !== -1) {
  //soft redirect
  window.location.href = window.location.hash.substr(1)
}
