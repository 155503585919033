let Soundplayer = function () {
  //THIS HAS TO BE SYNCED TO THE STYLESHEET
  var $navBarButton = null,
    $audioEl = null,
    //is the audio playing?
    isPlaying = false,
    //is the audio enabled? //this stays over a page lifetime
    isEnabled = false,
    _init = function (Foundation, $) {
      //assign the button of the nav bar
      $navBarButton = $(".util-nav .soundplayer")

      //register the audio element and its behavours
      $audioEl = $("audio")
      $audioEl.bind("ended", onEnded)
      $navBarButton.off("click").on("click", toggleButton)

      //enable on desktops
      isEnabled = window.device.desktop()

      //try to load a soundfile if available
      if (!$.isEmptyObject($audioEl.data("audio-data"))) {
        //console.log($audioEl.data('audio-data'));

        let soundfiles = $audioEl.data("audio-data").soundfiles
        //console.log(soundfiles);

        //are we in mobile or desktop mode?
        let soundfile
        if (Foundation.MediaQuery.current === "small") {
          soundfile = soundfiles.mobile
        } else {
          soundfile = soundfiles.default
        }

        if (soundfile !== undefined) {
          _load(soundfile, $audioEl.data("audio-data").autostart)
        }
      } else {
        updateButtonState()
      }
    },
    _load = function (soundfile, autostart = false) {
      if (soundfile.length > 0) {
        $audioEl[0].src = soundfile
        $audioEl[0].pause()
        $navBarButton.removeClass("inactive")
        $navBarButton.addClass("highlight")
      }

      if (autostart && isEnabled) {
        startAudioTrack()
      }
      updateButtonState()
    },
    startAudioTrack = function () {
      isPlaying = true
      $audioEl[0].play()
    },
    pauseAudioTrack = function () {
      $audioEl[0].pause()
      isPlaying = false
    },
    /* does a stop and UNLOAD */
    _stop = function () {
      $audioEl[0].src = ""
      $navBarButton.addClass("inactive")

      isPlaying = false
      updateButtonState()
    },
    toggleButton = function () {
      isEnabled = !isEnabled
      //now check if we have an audio to start
      if (isEnabled) {
        if ($audioEl[0].src.length > 0) {
          startAudioTrack()
        }
      } else {
        pauseAudioTrack()
      }
      updateButtonState()
    },
    updateButtonState = function () {
      if (isEnabled) {
        if (isPlaying) {
          $navBarButton.removeClass("is-playing").addClass("is-playing")
        } else {
          $navBarButton.removeClass("is-playing")
        }
      } else {
        $navBarButton.removeClass("is-playing")
      }
    },
    onEnded = function () {
      _stop()
    }

  //expose the render method
  return {
    init: _init,
    stop: _stop,
    load: _load,
  }
}.call()

module.exports = Soundplayer
