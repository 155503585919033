/* global Foundation */

let Flickity = require("flickity")
let Tearsheets = require("./tearsheets.js")

//enable thumb slider
require("flickity-as-nav-for")

//enable imagesloaded
require("flickity-imagesloaded")

let timelineData = []
let selectedIndex

function replaceHash(year) {
  // console.trace(year)
  if (window.history && window.history.replaceState) {
    window.history.replaceState({ year }, "", `./#${year}`)
  }
}

let Timeline = function () {
  var flkty = null,
    flktyHasSettled = true,
    TIMESTRIP_START_YEAR = 1945,
    TIMESTRIP_END_YEAR = 2025,
    initialIndex = window.location.hash.match(/\d+$/),
    useRandom = false,
    initialMediaQuery = null,
    _init = function (Foundation, $) {
      //does the page have a carousel?
      if ($(".project-slider").length > 0) {
        hideWhileLoading(".project-slider")

        initialMediaQuery = Foundation.MediaQuery.current
        // reload the page if the current breakpoint changes when device changes the orientation
        // this is the easiest way to reinitialize the project gallery
        window.addEventListener("orientationchange", () => {
          if (initialMediaQuery !== Foundation.MediaQuery.current) {
            window.location.reload()
          }
        })

        //carousel only for medium up
        if (Foundation.MediaQuery.current !== "small") {
          flkty = new Flickity(".project-slider", getFlicktyOptions())

          registerFlickityArrows()

          $(window).trigger("resize")

          //index only
          if ($(".content-box--project-slider").length > 0) {
            if (initialIndex == null) {
              useRandom = true
            }

            //collect the timeline data
            collectTimelineData()
            //init listener states
            onTimelineDragStop()
            //init start index from selected index in html
            onTimelineSlide(true)
            //show it
            $(".time-strip").removeClass("loading-in-progress")

            //use the state for flicking and then timeline drag
            flkty.on("dragStart", () => {
              flktyHasSettled = false
            })
            flkty.on("settle", () => {
              flktyHasSettled = true
              replaceHash(flkty.selectedIndex)
            })

            //reveal it TODO solve this different
            //$('.content-box--project-slider').removeClass('inactive');
            //$('.content-box--project-timestrip').removeClass('inactive');
          }
        } else if ($(".content-box--project-slider").length > 0) {
          if (initialIndex == null) {
            useRandom = true
          }
        }
      }

      //does the page have a thumb slider?
      if ($(".thumb-slider").length > 0) {
        //carousel only for medium up
        if (Foundation.MediaQuery.current !== "small") {
          hideWhileLoading(".thumb-slider")
          new Flickity(".thumb-slider", {
            imagesLoaded: true,
            freeScroll: true,
            freeScrollFriction: 0.03,
            percentPosition: false,
            contain: true,
            prevNextButtons: false,
            pageDots: false,
            selectedAttraction: 0.01,
            friction: 0.15,
            asNavFor: ".project-slider",
          })
        }
      }
    },
    getFlicktyOptions = function () {
      if ($(".content-box--project-slider").length > 0) {
        return {
          imagesLoaded: true,
          freeScroll: true,
          freeScrollFriction: 0.03,
          percentPosition: false,
          contain: true,
          prevNextButtons: false,
          pageDots: false,
          selectedAttraction: 0.01,
          friction: 0.15,
          setGallerySize: false,
        }
      } else if ($(".content-box--gallery-slider").length > 0) {
        return {
          imagesLoaded: true,
          pageDots: false,
          setGallerySize: false,
        }
      } else if ($(".content-box--gallery-index").length > 0) {
        return {
          imagesLoaded: true,
          pageDots: false,
          setGallerySize: false,
        }
      } else if ($(".content-box--gallery-strip").length > 0) {
        return {
          imagesLoaded: true,
          freeScroll: true,
          freeScrollFriction: 0.03,
          percentPosition: false,
          contain: true,
          // disable previous & next buttons and dots
          prevNextButtons: true,
          pageDots: false,
          selectedAttraction: 0.01,
          friction: 0.15,
          //setGallerySize: false,
        }
      }
      //default options object
      return {}
    },
    hideWhileLoading = function (selector) {
      $(selector)
        .imagesLoaded()
        //.always( function( instance ) {
        .always(() => {
          //small vp's do not have a flickity carousel object
          if (flkty !== null) {
            flkty.reposition()

            if (initialIndex && initialIndex.length) {
              let index = parseInt(initialIndex[0], 10)
              flkty.select(index || 0, false, true)
            } else {
              //no intital index, use random?
              if (!useRandom) {
                flkty.select(0, false, true)
              } else {
                //how many entries do we have?
                let rndElement = Math.floor(
                  Math.random() * $(".slider-cell").size()
                )
                //distinguis between mobile and desktop
                flkty.select(rndElement, false, true)
              }
            }
          } else if (initialIndex && initialIndex.length) {
            $(selector).removeClass("loading-in-progress")
            $(
              $(".slider-cell")[parseInt(initialIndex[0], 10)]
            )[0].scrollIntoView()
          } else {
            //no intital index, use random?
            if (useRandom) {
              //how many entries do we have?
              let rndElement = Math.floor(
                Math.random() * $(".slider-cell").size()
              )
              $($(".slider-cell")[rndElement])[0].scrollIntoView()
            }
          }
          //just reveal it, without thinking about errors in image loading
          $(selector).removeClass("loading-in-progress")
          //console.log('all images loaded');

          //only check after all images has been loaded
          if (selector === ".project-slider") {
            if (Foundation.MediaQuery.current !== "small") {
              checkForTearsheets()
            }
            checkForVideo()
          }
        })
        //.done( function( instance ) {
        .done(() => {
          //console.log('all images successfully loaded');
        })
        .fail(() => {
          //console.log('all images loaded, at least one is broken');
        })
      /*
            .progress( function( instance, image ) {
                var result = image.isLoaded ? 'loaded' : 'broken';
                console.log( 'image is ' + result + ' for ' + image.img.src );
            })*/
    },
    registerFlickityArrows = function () {
      $(window).resize(() => {
        let sp = parseInt(
          ($(window).width() - $(".flickity-viewport").width()) / 2
        )
        $(".flickity-prev-next-button.previous").css("left", -sp)
        $(".flickity-prev-next-button.next").css("right", -sp)
      })
    },
    onTimelineDragStart = function () {
      //check if project slider is still moving
      if (!flktyHasSettled) {
        //hack flickity, set the resting frames high so it
        //thinks it has settled, to be sure call settle manually
        //see: https://github.com/metafizzy/flickity/blob/master/js/animate.js#L162
        flkty.restingFrames = 999
        flkty.settle(flkty.x)
      }
      $(".project-slider").off("select.flickity")
      $("[data-slider]").off("mousedown")
      $("[data-slider]")
        .off("mouseup")
        .on("mouseup", onTimelineDragStop.bind(this))

      //trigger mouseup on leaving the window
      $("body")
        .off("mouseleave")
        .on("mouseleave", () => {
          $("[data-slider]").mouseup()
        })

      //register slider
      $("[data-slider]")
        .off("moved.zf.slider")
        .on("moved.zf.slider", onTimelineSlide.bind(this))
    },
    onTimelineDragStop = function () {
      $("[data-slider]").off("moved.zf.slider")
      $("[data-slider]").off("mouseup")
      $("body").off("mouseleave")
      $("[data-slider]")
        .off("mousedown")
        .on("mousedown", onTimelineDragStart.bind(this))
      $(".project-slider")
        .off("select.flickity")
        .on("select.flickity", onSliderDragMove.bind(this))
    },
    collectTimelineData = function () {
      $("[data-slide-year]").each((i, element) => {
        let year = $(element).data("slide-year")
        if (!timelineData[year]) {
          timelineData[year] = []
        }
        timelineData[year].push({
          element: $(element),
          sliderIndex: i,
        })
      })
    },
    onTimelineSlide = function (arg) {
      //set flickity selects method isInstant argument to true if onTimelineSlide has true as argument
      //this is only for initalization. NOTNICE but works
      let isInstant = typeof arg === "boolean"
      let v = $(".time-strip input").val()
      let year = Math.round(
        TIMESTRIP_START_YEAR +
          (v / 100) * (TIMESTRIP_END_YEAR - TIMESTRIP_START_YEAR)
      )
      //TODO just go to the next
      let lastTimeline = timelineData[timelineData.length - 1]
      let lastSlide = lastTimeline[lastTimeline.length - 1]
      if (timelineData[year]) {
        flkty.select(timelineData[year][0].sliderIndex, false, isInstant)
        replaceHash(timelineData[year][0].sliderIndex)
      } else if (year >= timelineData.length) {
        flkty.select(lastSlide.sliderIndex, false, isInstant)
        replaceHash(lastSlide.sliderIndex)
      } else {
        for (let y = year; y <= TIMESTRIP_END_YEAR; y++) {
          if (timelineData[y]) {
            year = y
            flkty.select(timelineData[year][0].sliderIndex, false, isInstant)
            replaceHash(timelineData[year][0].sliderIndex)
            break
          }
        }
      }
    },
    onSliderDragMove = function () {
      if (selectedIndex !== flkty.selectedIndex) {
        selectedIndex = flkty.selectedIndex
        let year = $(".slider-cell.is-selected").data("slide-year")
        replaceHash(flkty.selectedIndex)
        $(".time-strip input").val(
          Math.round(
            ((year - TIMESTRIP_START_YEAR) /
              (TIMESTRIP_END_YEAR - TIMESTRIP_START_YEAR)) *
              100
          )
        )
        $(".time-strip input").trigger("change")
      }
    },
    checkForTearsheets = function () {
      //check if tearsheets are available
      if ($(".flex-tearsheet-wrapper").length > 0) {
        Tearsheets.init($, flkty)
      }
    },
    checkForVideo = function () {
      //check if vimeo vids are present
      if ($(".vimeo-placeholder").length > 0) {
        $(".vimeo-placeholder").each((index, node) => {
          let imgHeight = $(node).find("img").height()
          $(node).find("iframe").attr("height", imgHeight)
          //apply swipe handle if not to small:
          //vimeo will show the play button in the center for vids less height than 200px
          if (imgHeight > 200) {
            $(node)
              .find(".swipe-handle")
              .css("height", $(node).find("img").height() - 50)
          } else {
            $(node).find(".swipe-handle").css("display", "none")
          }
        })
      }
    }

  //expose the render method
  return {
    init: _init,
  }
}.call()

module.exports = Timeline
