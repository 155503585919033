let Navigation = function () {
  var $element = null,
    Menu = null,
    $blocker = null,
    //render will be called from the injector
    _init = function (Foundation, $) {
      $element = $("#nav-offcanvas")

      $blocker = $(".off-canvas-blocker")
      Menu = new Foundation.AccordionMenu(
        $element.find("[data-accordion-menu]")
      )

      $element.off(".zf.toggler").on({
        "on.zf.toggler": open.bind(this),
        "off.zf.toggler": close.bind(this),
      })
    },
    open = function () {
      $blocker.show()
      //scroll to top and close all accordions
      $element.scrollTop(0)
    },
    close = function () {
      $element.scrollTop(0)
      Menu.hideAll()
      //f6 does not reset the aria expanded attributes
      $element.find("[aria-expanded]").attr("aria-expanded", false)

      $blocker.hide()
    }

  //expose the render method
  return {
    init: _init,
  }
}.call()

module.exports = Navigation
